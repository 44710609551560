<template>
  <div>
    <nav
      class="navbar navbar-expand fixed-top"
      style="min-height: 60px !important; background-color: #d71a20"
    >
      <div
        class="text-center mx-auto"
        style="position: absolute; left: 50%; transform: translateX(-50%)"
      >
        <!-- <img
          src="/img/cartao-renner.png"
          alt=""
          class=""
          style="width: 200px"
        /> -->
        <h1
          style="color: white; font-size: 1.5rem; font-weight: bold; margin: 0; display: flex;"
        >
          CARTÕES RENNER
        </h1>
      </div>
      <div class="container">
        <div id="navbar4" class="navbar-collapse collapse">
          <ul class="navbar-nav mr-auto text-white text-center"></ul>
          <div class="space1 d-none d-sm-block"></div>
        </div>
        <ul class="navbar-nav ml-auto text-secondary">
          <a
            href="index.html"
            class="sidebar-brand d-flex align-items-right justify-content-right"
          ></a>
          <li class="nav-item dropdown no-arrow">
            <a
              href="index.html"
              class="sidebar-brand d-flex align-items-right justify-content-right"
            ></a>
          </li>
        </ul>
      </div>
    </nav>
    <router-view></router-view>
    <!-- <a
      @click="saveLogcontatoWhatsApp"
      class="whatsapp-link"
      href="https://porto.vc/whatsapp-oficial"
      target="_blank"
    >
      <i
        v-bind:class="[
          largura < 500 ? 'fa fa-whatsapp fa-2x' : 'fa fa-whatsapp fa-3x',
        ]"
      ></i>
    </a> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ActionGetIPAddress } from "@/utils/helpers";

export default {
  name: "Landing",
  components: {},
  data: () => ({
    authlinks: [{ icon: "", text: "Início", route: "/dashboard" }],
    hrefwhatsapp: "https://api.whatsapp.com/send?l=pt&phone=551132303765",
    links: [
      { icon: "", text: "Quem Somos", route: "somos" },
      { icon: "", text: "Experimente", route: "experimente" },
      { icon: "", text: "Termos de uso", route: "termos" },
      { icon: "", text: "Política de privacidade", route: "sobre" },
      { icon: "", text: "Política de cookies", route: "sobre" },
    ],
    largura: null,
    ipUser: null,
  }),
  methods: {
    ...mapActions("auth", [
      "ActionDoLogout",
      "ActionMenuLinks",
      "ActionGetUser",
      "ActionGetWhatsText",
      "ActionContatoWhatsapp",
    ]),
    ...mapActions("meusBoletos", ["ActionSendAccessLogWithoutToken"]),

    async openWhats() {
      if (this.user != null) {
        var whats = await this.ActionGetWhatsText();
        this.hrefwhatsapp = this.hrefwhatsapp + "&text=" + whats[0];
      } else {
        // console.log('registrando log...');
        await this.ActionSendAccessLogWithoutToken({
          ValueId: 16,
        });
        this.hrefwhatsapp =
          this.hrefwhatsapp +
          "&text=Olá, não encontrei meus dados podem me ajudar ?";

        // console.log('log registrado.');
      }

      window.open(this.hrefwhatsapp, "_blank");
    },

    async saveLogcontatoWhatsApp() {
      this.ActionContatoWhatsapp({
        DocNumber: localStorage.getItem("doc") ?? null,
        tag: "Padrao",
        IP: this.ipUser ? this.ipUser : "",
      });

      this.clickWhatsapp();
    },

    clickLogo() {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "click:logo:porto-bank";
      console.log("eventCategory: " + JSON.stringify(eventCategory));
      console.log("eventAction: " + JSON.stringify(eventAction));

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    clickWhatsapp() {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "click:fab:whatsapp";
      console.log("eventCategory: " + JSON.stringify(eventCategory));
      console.log("eventAction: " + JSON.stringify(eventAction));

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      console.log("eventData: " + JSON.stringify(eventData));

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    async getIPAddressUser() {
      let maxAttempts = 10;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          this.ipUser = await ActionGetIPAddress();
          console.log("this.ipUser: " + JSON.stringify(this.ipUser));
          break; // Saia do loop se o IP for obtido com sucesso
        } catch (error) {
          console.log("Erro ao obter o endereço IP. Tentando novamente...");
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Aguarde 1 segundo antes de tentar novamente
        }
      }

      if (attempts === maxAttempts) {
        console.log(
          "Número máximo de tentativas atingido. Não foi possível obter o endereço IP."
        );
      }
    },
  },
  async created() {
    await this.getIPAddressUser();
    this.largura = window.screen.width;
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
<style scoped>
html {
  margin-top: -57px;
}
</style>
